@import "bulma/sass/utilities/initial-variables.sass";
@import "bulma/sass/utilities/functions.sass";

$purple: hsl(275, 87%, 45%);
$purple-invert: findColorInvert($purple);

@import "bulma/sass/utilities/derived-variables.sass";

$addColors: (
  "purple": (
    $purple,
    $purple-invert,
  ),
);

$colors: map-merge($colors, $addColors);

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@import "bulma/bulma";
